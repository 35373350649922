<script setup>
import { Head, router } from "@inertiajs/vue3";
import Banner from "@/Components/Banner.vue";
import {computed, getCurrentInstance, ref} from "vue";
import { useDisplay } from "vuetify";
import LanguageSwitcher from "@/Components/LanguageSwitcher.vue";
import AppProcessor from "./AppProcessor.vue";
import { usePreferencesStore } from "../stores/preferencesStore";
import { createPinia } from "pinia";
import DarkModeSwitcher from "../Components/DarkModeSwitcher.vue";

defineProps({
    title: String,
});

const drawerOpen = ref(false);
const temporaryDrawer = computed(() => {
    return useDisplay().smAndDown.value;
});

const pinia = createPinia();
const preferences = usePreferencesStore(pinia);

const vue = getCurrentInstance();
const settings = vue.appContext.config.globalProperties.$settings;

const headerImages = [
    "/img/header-images/header-image1.jpg",
    "/img/header-images/header-image2.jpg",
];

const randomImage = () => {
    var img = sessionStorage.getItem("headerImage");
    const timestamp = sessionStorage.getItem("headerImageTimestamp");
    console.log(
        timestamp,
        Math.floor(Date.now() / 1000) -
            timestamp +
            " " +
            Math.floor(Date.now() / 1000)
    );
    if (img == null || Math.floor(Date.now() / 1000) - timestamp > 5) {
        console.log("New Image");
        img = headerImages[Math.floor(Math.random() * headerImages.length)];
        sessionStorage.setItem(
            "headerImageTimestamp",
            Math.floor(Date.now() / 1000)
        );
        sessionStorage.setItem("headerImage", img);
    }
    return img;
};
</script>

<template>
    <div :class="{'tw-dark': preferences.state.darkMode}">
        <!-- TODO This does not work! --->
        <Banner></Banner>
        <app-processor></app-processor>

        <Head :title="title" />

        <v-card class="tw-min-h-screen tw-bg-gray-100 dark:tw-bg-zinc-900" :class="preferences.state.darkMode ? 'dark:tw-bg-zinc-900':''">
            <v-layout>
                <v-app-bar color="blue-darken-4" density="comfortable">
<!--                    <v-app-bar-nav-icon-->
<!--                        v-if="temporaryDrawer"-->
<!--                        density="comfortable"-->
<!--                        @click="drawerOpen = true"-->
<!--                    ></v-app-bar-nav-icon>-->

                    <v-img
                        src="/img/fll_horz_i.png"
                        class="tw-ml-3 flex-shrink-1"
                        max-height="100"
                        max-width="150"
                        contain
                    ></v-img>

                    <v-btn :href="route('scoreboard.index')"> {{ $vuetify.locale.t("menu.scoreboard") }} </v-btn>
                    <v-btn v-if="settings.modules('ModuleSchedule', true)" :href="route('public.schedule.index')"> {{ $vuetify.locale.t("menu.schedule") }} </v-btn>
                    <v-btn :href="route('public.teamlist.index')"> {{ $vuetify.locale.t("menu.teams") }} </v-btn>
                    <v-btn v-if="settings.modules('ModuleLivestream', true)" :href="route('public.livestream')"> {{ $vuetify.locale.t("Livestream") }} </v-btn>
                    <v-btn :href="route('login')" class="tw-mr-auto "> {{ $vuetify.locale.t("menu.login") }} </v-btn>

                    <DarkModeSwitcher />

                    <LanguageSwitcher />

<!--                    <v-btn :href="route('login')"> Login </v-btn>-->
                </v-app-bar>

                <v-main>
                    <!-- Page Content -->
                    <main>
                        <header class="tw-bg-white tw-shadow">
                            <v-img
                                cover
                                style="
                                    max-height: 100px;
                                "
                                :src="randomImage()"
                            >
                            </v-img>
                        </header>
                        <slot />
                    </main>
                </v-main>
            </v-layout>
        </v-card>
    </div>
</template>
